<ng-container *ngIf="data">
  <section class="modal-box" *ngIf="skip && skipType">
    <div class="popup">
      <h1 [innerHtml]="ui_messages[skipType]?.title"></h1>
      <p class="text" [innerHtml]="ui_messages[skipType]?.content"></p>
      <div class="buttons-container">
        <ng-container *ngIf="skipType === 'no_credit_card_with_rule'; else no_credit_card_without_rule">
          <button class="btn-filled small-width" (click)="setSkip(false)" translate>{{'service.payment.auth.back_to_' + (data.paymentProcess === processType.pre_auth ? 'auth' : 'payment')}}</button>
          <button class="btn-outline small-width" (click)="confirmSkip()" translate>{{'service.payment.auth.' + (data.paymentProcess === processType.pre_auth ? 'pay_now' : 'auth_now')}}</button>
        </ng-container>
        <ng-template #no_credit_card_without_rule>
          <button class="btn-outline small-width" (click)="confirmSkip()" translate>misc.continue</button>
          <button class="btn-filled small-width" (click)="setSkip(false)" translate>service.payment.auth.back_to_auth</button>
        </ng-template>
      </div>
    </div>
  </section>

  <form #form="ngForm" class="redesign">
    <ng-container *ngIf="loaded; else loading">
      <ng-container *ngIf="!data.folioInfo.error; else retry">
        <p [innerHTML]="'service.folios.partial' | translate" *ngIf="(suite8 || opera) && data.incident.reservation.folios.length && !showHints"></p>

        <div class="folios-loaded" *ngFor="let folio of data.incident.reservation.folios; let i = index, let last = last" [class.last]="last">
          <app-pms-folio [hidden]="cicoService.displayedFolioIndex != i && cicoService.displayedFolioIndex >= 0" [folio]="folio" [data]="data" [number]="i" [staticAddress]="!collectAddress" [payment]="(data.paymentProcess === processType.payment || payFromResModule())" [showHints]="showHints" *ngIf="folio.positions?.length || suite8"></app-pms-folio>
        </div>
      </ng-container>

      <ng-container *ngIf="cicoService.folioAddressStep == 'folio' && (data.folioInfo.error || data.folioInfo.noFolios)">
        <p class="no-folios heading-container"> <i class='fa-solid fa-circle-info'></i> <span [innerHTML]="'service.folios.no_folios'+foliosErrorMsg | translate"></span></p>
      </ng-container>

      <div class="heading-container" *ngIf="onlyAdress">
        <h2 translate>service.folios.invoice_address</h2>
        <app-pms-invoice-address-form [data]="data" [newDesign]="true" [staticAddress]="false" [withoutFolio]="true"></app-pms-invoice-address-form>
      </div>

      <ng-container *ngIf="cicoService.folioAddressStep == 'folio' && data.paymentProcess === processType.pre_auth && data.preAuth && data.preAuth.amount > 0 && !data.preAuth.fullyAuthorized">
        <div class="auth-info" *ngIf="data.preAuth?.info">
          <p [innerHTML]="data.preAuth.info.with_folio" *ngIf="!(data?.incident?.reservation?.pre_auth?.auth_without_folio && data?.folioInfo?.error) && data?.incident.reservation?.folios?.length"></p>
          <ng-container *ngIf="data.incident?.reservation.pre_auth?.auth_without_folio">
            <p [innerHTML]="data.preAuth.info.without_folio" *ngIf="!data.folioInfo?.error && !data?.incident.reservation?.folios?.length"></p>
            <p [innerHTML]="data.preAuth.info.with_folio_error" *ngIf="data.folioInfo?.error"></p>
          </ng-container>
        </div>

        <ng-template [ngTemplateOutlet]="authButtons"></ng-template>
        <div class="btn-grid" *ngIf="reloadButton">
          <button type="button" class="btn-filled" id="retry_auth" (click)="setFolioSettings()" translate>
            <i class="fas fa-rotate-right"></i> service.payment.loading.buttons.load
          </button>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #retry>
      <ng-container *ngIf="!(data.paymentProcess === processType.pre_auth && data.incident.reservation.pre_auth.auth_without_folio)">
        <ng-template [ngTemplateOutlet]="buttons" *ngIf="showHints"></ng-template>
      </ng-container>
    </ng-template>

    <ng-template #loading>
      <app-loader [small]="true" [text]="msgToDisplay" [headline]="msgToDisplayHeadLine"></app-loader>
      <ng-template [ngTemplateOutlet]="buttons" *ngIf="showHints"></ng-template>
    </ng-template>

    <ng-template #buttons>
      <div class="btn-grid">
        <button type="button" class="btn-filled small-width" (click)="loadFolios()" translate *ngIf="reloadButton">
          <i class="fas fa-rotate-right"></i> service.payment.loading.buttons.load
        </button>
        <button type="button" class="btn-filled small-width" (click)="skipIt()" translate *ngIf="skipButton">
          misc.skip <i class="fas fa-forward"></i>
        </button>
      </div>
    </ng-template>

    <ng-template #authButtons>
      <div class="btn-grid half-fill">
        <button class="btn-filled small-width" (click)="openPreAuth($event)">{{'service.payment.authorize_now' | translate: data.preAuth }}</button>
        <button class="btn-outline small-width" (click)="skipPreAuth()" translate>{{'service.payment.no_card'}}</button>
      </div>
    </ng-template>

    <p *ngIf="data?.preAuth?.authorizationDone && cicoService.folioAddressStep === 'folio'">
      <i class="fas fa-check text-success"></i>
      <span>{{'service.payment.authorized' | translate: {pre_auth_balance_with_currency: data.preAuth.balance_with_currency} }}</span><br />
      <span [innerHtml]="ui_messages.authorized?.content"></span>
    </p>

  </form>
</ng-container>
