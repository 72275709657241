import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Address } from 'models/address';
import { Field } from 'models/field';
import { GenericCheckIn } from 'models/pms/generic_check_in';
import { GenericData } from 'models/pms/generic_data';
import { PmsGuest } from 'models/pms/pms_guest';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PassportPhotoService } from 'services/passport-photo.service';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { WebsocketService } from 'services/websocket/websocket.service';
import { PrimaryGuestSubStepState } from 'models/pms/stepper';

@Component({
  selector: 'app-pms-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class PmsGuestComponent implements OnInit, OnDestroy {
  @Input() guest: PmsGuest;
  @Input() index: number;
  @Input() kind: string = '';
  @Input() field: Field;
  @ViewChild('confirm_email_field') confirmField: NgModel;

  subscriptions = new Subscription();
  search: string;
  name = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  data: GenericData;
  primaryGuest: boolean;
  fields: any;
  fieldsLoaded: boolean;
  phoneCountry: string;
  useSavedAddress = false;
  primaryFormSubmited: boolean;
  privateFormSubmited: boolean;
  identificationFormSubmited: boolean;
  transportation: boolean;
  guestIndex: any;
  genders: any[];
  showCamera: boolean;
  checkNationality = true;
  settings: any;
  authRequired: string = '';
  isShowIdnowSection: boolean;
  idnow: boolean;
  deviatingBillingAdr: boolean;

  constructor(public globals: Globals,
              public cicoService: PmsCiCoService,
              private passportPhotoService: PassportPhotoService,
              private readonly eventService: EventAggregatorService,
              private _cdr: ChangeDetectorRef,
              private wsService: WebsocketService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.passportPhotoService.cameraVisible$.subscribe(visible => {
      this.showCamera = visible;
      this._cdr.detectChanges();
    }));

    // When idnow is activated and the user clicks back, show the idnow section.
    this.subscriptions.add(this.eventService.getEvent(EventConstants.toShowIdNowWhenUserClickBack).subscribe(() => {
      if (this.idnow) {
        this.data.authChosen = false;
        this.isShowIdnowSection = true;
        this.cicoService.setShowFooter(false);
      }
    }));

    // When a form is submitted, set the 'submitted' flag to true. this is for showing the validation satate of the input.
    // TODO: This is a temporary solution, this code will be remvoed in new desgin of the form.
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.afterSubmitForm).subscribe(() => {
        switch (this.cicoService.subStepForPrimaryGuest) {
          case PrimaryGuestSubStepState.primaryGuest:
            this.primaryFormSubmited = true;
            break;
          case PrimaryGuestSubStepState.privateAddress:
            this.privateFormSubmited = true;
            break;
          case PrimaryGuestSubStepState.identificationGuest:
            this.identificationFormSubmited = true;
            break;
          case PrimaryGuestSubStepState.otherGuest:
            this.primaryFormSubmited = true;
            this.privateFormSubmited = true;
            this.identificationFormSubmited = true;
            break;
        }
      })
    );
    
    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
      this.setGenders();
      this.transportation = ['ch'].includes(this.data.business.address.country);
      this.primaryGuest = this.guest.group === 'primary_guest';
      // If the current step is a sub-step of primary step, hide the back button, only when sub step is primary_guest.
      if (this.cicoService.subStepForPrimaryGuest === PrimaryGuestSubStepState.primaryGuest) {
        this.cicoService.hideBackButton(this.primaryGuest);
      }

      if (!this.transportation && this.field_for('car_licence')) {
        const transp = this.data.incident.reservation.car_licence;
        (<GenericCheckIn>this.data.incident).transportation = transp ? 'private' : 'public';
      }

      this.setFields();
      this.checkDocuments();
      this.initialCountry();
      this.setAddressName();

      this.subscriptions.add(this.cicoService.fieldsUpdated.pipe(filter(Boolean)).subscribe(() => {
        if (this.primaryGuest) {
          this.field = this.cicoService.field_for('primary_guest');
          this.setFields();
          this._cdr.detectChanges();
        }
      })); 

      if (this.primaryGuest) {
        this.checkDeviatingBillingAdress();
        this.checkIdnow();
      }
    });

    this.settings = this.cicoService.field_for('primary_guest')?.subField('passport')?.subField('passport_image')?.settings;
    this.authRequired = this.setting_for(this.domestic_guest() ? 'validation_domestic' : 'validation_foreigners');

    this._cdr.detectChanges();
  }

  setFields() {
    this.fields = {};
    ['first_name', 'last_name', 'gender', 'nationality', 'date_of_birth', 'place_of_birth', 'email', 'phone', 'private_address', 'profession', 'invoice_address'].forEach((name) => {
      this.fields[name] = this.field_for(name);
    });

    [['car_licence', null], ['marketing', null]].forEach((entry) => {
      this.fields[entry[1] ? entry[1] : entry[0]] = this.travel_info(entry[0], entry[1]);
    });
    this.fieldsLoaded = true;
    this._cdr.detectChanges();
  }

  checkFields() {
    if (this.primaryGuest) {
      this.cicoService.updatedFields();
    }
  }

  checkDocuments() {
    this.cicoService.setPassportVisa();
  }

  checkDeviatingBillingAdress() {
    this.deviatingBillingAdr = this.fields['invoice_address']?.active;
    const address = this.data.incident.reservation.address;

    if (this.cicoService.deviatingBillingAddress === undefined) {
      this.cicoService.deviatingBillingAddress = (address && !address.isEmpty() && address.internal_id !== this.guest.internal_id) ? true : null;
    }
  }

  domestic_guest(): boolean {
    return this.data?.incident?.reservation?.primary_guest?.domestic(this.data?.business);
  }

  setting_for(name) {
    return this.settings?.find(f => f.identifier === name)?.value;
  }

  setGenders() {
    this.genders = (this.data.module.settings.genders || ['male', 'female', 'other']).map(gender => {
      return {name: this.globals.translate(`booking_data.gender.${gender}`), value: gender};
    });
  }

  initialCountry() {
    if (this.fields.phone) {
      if (!this.guest.phone?.length) {
        this.phoneCountry = (this.guest.address?.country || this.guest.nationality || this.globals.country)?.toLowerCase();
      } else {
        this.phoneCountry = 'defined';
      }
    }
  }

  field_for(id, sub = null): Field {
    const result = this.field?.fields?.find(field => field.identifier === id);
    return sub == null ? result : result?.fields?.find(field => field.identifier === sub);
  }

  travel_info(id, sub = null): Field {
    const result = this.data?.module?.field(id) || this.cicoService.field_for(id);
    return sub == null ? result : result?.fields?.find(field => field.identifier === sub);
  }

  applyAddress() {
    this.useSavedAddress = !this.useSavedAddress;
    if (this.useSavedAddress) {
      this.guest.address = new Address(this.copyData());
    } else {
      this.guest.address = Object.assign({});
    }
  }

  setAddressName() {
    if (this.primaryGuest) {
      this.cicoService.setAddressName();
    }
  }

  copyData(): any {
    const address = this.data.incident.reservation.primary_guest.address;
    return {
      first_name: this.guest.first_name,
      last_name: this.guest.last_name,
      address: address.address,
      zipcode: address.zipcode,
      city: address.city,
      state: address.state,
      country: address.country
    };
  }

  checkIdnow(done = false) {
    if (done) {
      this.isShowIdnowSection = false;
      this.checkNationality = false;
      this.cicoService.setPassportVisa();
      this.cicoService.hideBackButton(true);
    } else {
      this.idnow = this.data.incident.reservation.idnow();
      // If the current step is a sub-step of primary step, hide the back button only when sub step is primary guest.
      if (this.cicoService.subStepForPrimaryGuest === PrimaryGuestSubStepState.primaryGuest) {
        this.cicoService.hideBackButton(!this.idnow);
      }
      this.checkNationality = !this.idnow;

      if (this.data.incident.reservation.skipable && this.idnow) {
        // If user can skip and idnow is activated, hide footer and idnow section.
        // This hide is temporary for auto stepper activation.
        this.isShowIdnowSection = false;
      } else {
        this.isShowIdnowSection = this.wsService.socketConnected && !this.data.authChosen && this.idnow;
        this.cicoService.setShowFooter(!this.isShowIdnowSection);
      }
    }
  }

  dismissed() {
    this.isShowIdnowSection = false;
    this.cicoService.setShowFooter(true);
    window.scrollTo({top: 0, behavior: 'smooth'});

    // record the user actions
    this.globals.viewSubj.next(`${this.data.module.type}-${this.cicoService.subStepForPrimaryGuest}`);
  }

  deviatingAddrToggleChange() {
    if (this.cicoService.deviatingBillingAddress === null) {
      this.data.incident.reservation.address = new Address(null);
      this.data.incident.reservation.folios.forEach(folio => folio.address = this.data.folioAddresses.find(adr => adr.number === folio.number)?.address);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
